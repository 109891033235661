import classNames from 'classnames';
import { graphql } from 'gatsby';
import React, { ChangeEvent, Component, FC, useState } from 'react';
import { Helmet } from 'react-helmet';
import { GatsbyTemplateProps } from '../../common/interfaces/gatsby-template-props.interface';
import { AppContextProps } from '../../common/interfaces/locale-props.interface';
import { Divider } from '../common/Divider';
import { Button, Container, Row, Col } from '../common/Elements';
import { TopNav } from '../layout';
import { MainFooter } from '../layout/footer/footer.interfaces';
import Layout from '../layout/layout';
import { Grid, GridRow, GridCol } from '../welcome-center/common/Grid';
import { HeroHeader } from '../welcome-center/common/HeroHeader';
import { ResourcesGrid } from '../welcome-center/common/ResourcesGrid';
import { ContentfulNavigation } from '../../contentful/content-types/navigation';

export const query = graphql`
  query($locale: String!) {
    navData: mainTopNav(contentfulId: { eq: "3Ygy1wQCWTJl9iFRLTd0NV" }, locale: { eq: $locale }) {
      ...mainTopNavFragment
    }
    redwoodNavData: navNavigation(
      contentfulId: { eq: "5BEIZi0JdHhdU8ABizjFbY" }
      locale: { eq: $locale }
    ) {
      ...navigationFragment
    }
    redwoodRightNavData: navNavigation(
      contentfulId: { eq: "55yBvRCoANQ6LSDRS8y34S" }
      locale: { eq: $locale }
    ) {
      ...navigationFragment
    }
    utilityNavData: navNavigation(
      contentfulId: { eq: "3Ubk7uPrpCVkhQ3ZYXZLOp" }
      locale: { eq: $locale }
    ) {
      ...navigationFragment
    }
    footerData: mainFooter(locale: { eq: $locale }) {
      ...mainFooterFragment
    }
  }
`;

export interface AboutYourWebsiteProps extends GatsbyTemplateProps<AppContextProps> {
  data: {
    navData: TopNav;
    footerData: MainFooter;
    redwoodNavData: ContentfulNavigation;
    redwoodRightNavData: ContentfulNavigation;
    utilityNavData: ContentfulNavigation;
  };
}

interface SharedQuestionProps {
  className?: string;
}

export const Quiz: FC<SharedQuestionProps> = ({ children, className }) => {
  return (
    <section className={classNames('center flex flex-column flex-wrap', className)}>
      {children}
    </section>
  );
};

export const QuestionGroup: FC<SharedQuestionProps> = ({ children, className }) => {
  return <div className={classNames('w-100', className)}>{children}</div>;
};

export const Question: FC<SharedQuestionProps> = ({ children, className }) => {
  return <h2 className={classNames('lh-title f6-l f5 b', className)}>{children}</h2>;
};

interface OptionProps {
  value: string;
  index: number;
  isSelected: boolean;
  onSelect: () => void;
}

export const QuizOption: FC<OptionProps> = ({ children, value, index, isSelected, onSelect }) => {
  return (
    <div
      className={classNames('b ba b--black radio-select flex-auto flex flex-column h-74px', {
        'bg-br-lightBlue b--none': isSelected,
      })}
    >
      <input
        type="checkbox"
        id={`radioselect-${index}-${children}-${value}`}
        value={`${index}${value}`}
        checked={isSelected}
        onChange={onSelect}
      />
      <label
        className="ph3 pv1 db w-100 b flex-auto"
        htmlFor={`radioselect-${index}-${children}-${value}`}
      >
        {children}
      </label>
    </div>
  );
};

const questions = [
  {
    id: '1',
    question: 'I am choosing Cloudflare to...',
    hint: 'Select all that apply',
    multiselect: true,
    options: [
      { text: 'Secure and accelerate websites and apps', value: 'a' },
      { text: 'Secure apps and internet access for employees', value: 'b' },
      { text: 'Protect and accelerate corporate networks', value: 'c' },
      { text: 'Build and deploy code at the edge', value: 'd' },
    ],
  },
  {
    id: '2',
    question: 'I am using Cloudflare for my...',
    hint: 'Please select one',
    options: [
      { text: 'Personal use', value: 'a' },
      { text: 'Small business', value: 'b' },
      { text: 'Medium business', value: 'c' },
      { text: 'Large enterprise', value: 'd' },
      { text: 'Public sector organization', value: 'e' },
    ],
  },
  {
    id: '3',
    question: 'When I need support, I expect a response within...',
    hint: 'Please select one',
    options: [
      { text: 'I can self serve', value: 'a' },
      { text: 'Within 1 day', value: 'b' },
      { text: 'Within a few hours', value: 'c' },
      { text: 'Immediately', value: 'd' },
    ],
  },
  {
    id: '4',
    question: 'The following things are very important to me...',
    hint: 'Select all that apply',
    multiselect: true,
    options: [
      { text: 'Increasing website speed', value: 'a' },
      { text: 'Increasing website security', value: 'b' },
      { text: 'Increasing website reliability', value: 'c' },
      { text: 'Securing financial transactions', value: 'd' },
      { text: 'Streaming videos on my website', value: 'e' },
      { text: 'Protecting employees from online threats', value: 'f' },
      { text: 'Protecting apps from unauthorized access', value: 'g' },
    ],
  },
];

type QuestionId = typeof questions[number]['id'];

const AboutYourWebsitePage: FC<AboutYourWebsiteProps> = ({ data, pageContext }) => {
  const [selectedAnswers, setSelectedAnswers] = useState<{ [K in QuestionId]?: string[] }>({});
  /*

      function obsToString(o, k) {
        return k.keys().map(k => o[key]).filter(v => v).join('');
      }

      console.log(obsToString(selectedOptions))
      1a2b3a...
    */

  const nextPage = function () {
    let serializedAnswers = Object.entries(selectedAnswers)
      .map(([key, val]) => val?.map((x) => `${key}${x}`))
      .flat()
      .join('');

    const urlWithParams = new URL(`${window.location.href}recommendation/`);
    urlWithParams.searchParams.append('pr', serializedAnswers);

    window.location.href = urlWithParams.href;
  };

  const [currentStep, setCurrentStep] = React.useState(0);
  const gotoNextStep = () => {
    if (currentStep < questions.length - 1) {
      setCurrentStep(currentStep + 1);
    } else {
      nextPage();
    }
  };
  const goToPreviousStep = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  return (
    <Layout
      pageContext={pageContext}
      topNavData={data.navData}
      redwoodNavData={data.redwoodNavData}
      redwoodRightNavData={data.redwoodRightNavData}
      utilityNavData={data.utilityNavData}
      footerData={data.footerData}
    >
      <Helmet>
        <title>Cloudflare</title>
      </Helmet>

      <Container>
        <Row>
          <Col lg={4}>
            <HeroHeader
              title="Some quick questions for personalized recommendations"
              subtitle="Skip questions and see all plans ›"
              subtitleLink="/plans/"
              orientation="left"
              trackingCategory="recommendation_quiz"
              trackingAction="text_link_click"
              trackingLabel="skip_questions_and_see_all_plans"
              fullHeight={true}
            ></HeroHeader>
          </Col>
          <Col lg={8}>
            <Quiz>
              <p className="mt4 mb0">Question {currentStep + 1} of 4</p>
              <QuestionGroup key={currentStep + 1}>
                <Question key={`question-${questions[currentStep].id}`}>
                  {currentStep + 1}. {questions[currentStep].question}
                </Question>
                <p>{questions[currentStep].hint}</p>
                <Container marginTop={[0, 6]}>
                  <Row>
                    {questions[currentStep].options.map((option, index) => (
                      <Col sm={6} key={index + 1} marginBottom={3} display="flex" width="100%">
                        <QuizOption
                          value={option.value}
                          index={currentStep + 1}
                          isSelected={
                            selectedAnswers[questions[currentStep].id]?.some(
                              (item) => item === option.value
                            ) || false
                          }
                          onSelect={() => {
                            let curVal = selectedAnswers[questions[currentStep].id]; // string[] | undefined

                            // for single select questions
                            if (!curVal || !questions[currentStep].multiselect) {
                              return setSelectedAnswers({
                                ...selectedAnswers,
                                [questions[currentStep].id]: [option.value],
                              });
                            }

                            // In this case, question is multi select
                            setSelectedAnswers({
                              ...selectedAnswers,
                              [questions[currentStep].id]: curVal.some(
                                (item) => item === option.value
                              )
                                ? curVal.filter((item) => item !== option.value)
                                : curVal.concat(option.value),
                            });
                          }}
                        >
                          {option.text}
                        </QuizOption>
                      </Col>
                    ))}
                  </Row>
                </Container>
              </QuestionGroup>
              <Row>
                <Button
                  fontWeight="bold"
                  color={currentStep === 3 ? 'white' : 'builtforthisblue'}
                  backgroundColor={currentStep === 3 ? 'blue1' : 'white'}
                  width={['80%', '100%']}
                  paddingVertical={2}
                  marginHorizontal={[5, 4]}
                  marginTop={[0, 7]}
                  marginBottom={3}
                  border="all"
                  borderColor="builtforthisblue"
                  className="b--builtForThisBlue pointer dim"
                  onClick={() => {
                    gotoNextStep();
                  }}
                  data-tracking-category="recommendation_quiz"
                  data-tracking-action="button_click"
                  data-tracking-label={
                    currentStep === 3 ? 'submit' : `completed_step_${currentStep + 1}`
                  }
                >
                  {currentStep === 3 ? 'Submit' : 'Next'}
                </Button>
              </Row>
              <Row marginBottom={7}>
                {currentStep > 0 ? (
                  <Button
                    fontWeight="bold"
                    color="builtforthisblue"
                    backgroundColor="white"
                    paddingVertical={[1, 0]}
                    marginHorizontal={4}
                    width={['90%', '100%']}
                    marginTop={0}
                    marginBottom={4}
                    border="none"
                    borderColor="builtforthisblue"
                    className="b--builtForThisBlue pointer dim"
                    onClick={() => goToPreviousStep()}
                    data-tracking-category="recommendation_quiz"
                    data-tracking-action="button_click"
                    data-tracking-label="start_over"
                  >
                    &lt; Previous
                  </Button>
                ) : (
                  ''
                )}
              </Row>
            </Quiz>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default AboutYourWebsitePage;
